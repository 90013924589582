import React from "react";
import "./other-cards.scss";
import Link from "gatsby-link";

const OtherCard = ({ item }) => {
	const { title, code, image: { publicURL } = {}, description } = item;

	return (
		<section className="other-card">
			<div className="other-card__header">
				<div className="other-card__header__info">
					<h2 className="other-card__header__title">{title}</h2>
					<p className="other-card__header__description">{description}</p>
					<Link
						className="hidden-xs other-card__header__link link-s"
						to={`/cards?type=${code}`}
					>
						Подробнее
					</Link>
				</div>
				<div className="other-card__header__image">
					<img src={publicURL} alt="Изображение карты" />
				</div>
			</div>
			<div className="hidden-sm hidden-md other-card__body">
				<Link className="link-s" to={`/cards?type=${code}`}>
					Подробнее
				</Link>
			</div>
		</section>
	);
};

const OtherCards = ({ cards }) => {
	return cards && cards.length ? (
		<section className="other-cards">
			<div className="grid-container">
				<div className="row">
					{cards &&
						cards.length &&
						cards.map(card => (
							<div
								className="col-xs-12 col-sm-12 col-md-offset-2 col-md-8"
								key={card.id}
							>
								<OtherCard item={card} />
							</div>
						))}
				</div>
			</div>
		</section>
	) : null;
};

export default OtherCards;
