import React, { useEffect, useState, useCallback } from "react";
import CardCost from "@components/card/card-cost";
import Tariffs from "@components/card/tariffs";
import AdvantageOfUsing from "./advantage-of-using";
import HowToGet from "./how-to-get";
import MapOfBranches from "@components/maps/map-of-branches";
import { connect } from "react-redux";
import cn from "classnames";
import OtherCards from "@page-contents/other-cards";

import { navigate } from "gatsby";

import "./cards.scss";
import Helmet from "react-helmet";

const CardsComponent = props => {
	const { cards, pageContext: { code } = {}, cityId } = props;
	const [navScrollContainer, setNavScrollContainer] = useState(undefined);

	let root = null;

	const [currentCard, setCurrentCard] = useState(undefined);
	const [basicCards, setBasicCards] = useState([]);
	const [otherCards, setOtherCards] = useState([]);
	const [tariffsByCity, setTariffsByCity] = useState(undefined);

	useEffect(() => {
		if (cards && cards.length) {
			let current = cards.find(card => card.code === code);
			current && setCurrentCard(current);
			setBasicCards(cards.filter(card => !card.other_cards));
			setOtherCards(cards.filter(card => card.other_cards));
		}
	}, [cards, code]);

	useEffect(() => {
		if (currentCard) {
			const tariffs = currentCard.tariffs ? currentCard.tariffs : [];
			setTariffsByCity(
				tariffs.filter(tariff => {
					return (
						tariff.cities &&
						tariff.cities.length &&
						tariff.cities.includes(cityId)
					);
				})
			);
		}
	}, [cityId, currentCard, setTariffsByCity]);

	const handleClick = useCallback(
		to => e => {
			e.preventDefault();
			const box = e.target.getBoundingClientRect();
			navScrollContainer.scrollLeft += box.left - 16;
			navigate(to);
		},
		[navScrollContainer]
	);

	useEffect(() => {
		if (
			navScrollContainer &&
			(currentCard ||
				(code === "other" && otherCards && !!otherCards.length)) &&
			root
		) {
			const el = root.querySelector(
				`[id='${code === "other" ? 0 : currentCard.id}']`
			);
			if (el) {
				navScrollContainer.scrollLeft = el.offsetLeft;
			}
		}
	}, [currentCard, otherCards, root, code, navScrollContainer]);

	const renderStickyCardMenu = () => {
		const { other_cards } = currentCard || {};
		return (
			<section className="full-width sticky">
				<div className="sticky__wrapper" ref={el => setNavScrollContainer(el)}>
					<div className="grid-container">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-offset-2 col-md-8">
								<ul
									ref={c => {
										root = c;
									}}
									className="cards-menu"
								>
									{basicCards &&
										!!basicCards.length &&
										basicCards.map(({ code: codeCard, title, id }) => {
											return (
												<li key={id} className="cards-menu__item">
													<button
														id={id}
														className={cn(
															{ [`active`]: codeCard === code },
															codeCard
														)}
														onClick={handleClick(`/cards?type=${codeCard}`)}
													>
														{title.split(" ")[0]}
													</button>
												</li>
											);
										})}
									{otherCards && !!otherCards.length && (
										<li key={0} className="cards-menu__item">
											<button
												id={0}
												className={cn(
													{ [`active`]: code === "other" || other_cards },
													"other"
												)}
												onClick={handleClick("/cards?type=other")}
											>
												Прочие
											</button>
										</li>
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	};

	const renderMap = useCallback(
		() =>
			code !== "other" && currentCard && <MapOfBranches defaultType="refill" />,
		[code, currentCard]
	);

	const renderBasicCards = currentCard => {
		if (currentCard) {
			const {
				title,
				description,
				cost,
				image: { publicURL } = {},
				advantage_of_using,
				how_to_get
			} = currentCard;

			return (
				<>
					<Helmet title={`${title}`} />
					<div className="grid-container cards__top">
						<div className="row">
							<div className="col-xs-12 col-sm-6 col-md-offset-2 col-md-5">
								<h2>{title}</h2>
								<p className="cards__description">{description}</p>
								<CardCost className="cards__cost" value={cost} />
							</div>
							<div className="col-xs-12 col-sm-6 col-md-3">
								<img
									className="cards__image"
									src={publicURL}
									alt="Изображение карты"
								/>
							</div>
							<div className="hidden-xs col-sm-2 col-md-2" />
						</div>
					</div>
					{advantage_of_using && !!advantage_of_using.length && (
						<AdvantageOfUsing
							className="full-width"
							list={advantage_of_using}
						/>
					)}
					{how_to_get && !!how_to_get.length && (
						<HowToGet className="full-width" list={how_to_get} />
					)}
					{tariffsByCity && !!tariffsByCity.length && (
						<Tariffs data={tariffsByCity} code={code} />
					)}
				</>
			);
		} else {
			return (
				<div className="grid-container cards__top">
					<div className="row">
						<div className="col-xs-12 col-sm-6 col-md-offset-2 col-md-5">
							Указанная карта не найдена
						</div>
					</div>
				</div>
			);
		}
	};

	return (
		<>
			<section className="cards">
				{(currentCard || code === "other") && renderStickyCardMenu()}
				{code !== "other" ? (
					renderBasicCards(currentCard)
				) : (
					<OtherCards cards={otherCards} />
				)}
			</section>
			{renderMap()}
		</>
	);
};

const mapStateToProps = ({ city, staticData: { cities, cards } = {} }) => {
	let id;
	if (city && cities && cities.length) {
		const cityId = cities.find(item => item.name === city);
		id = cityId && cityId.strapiId;
	}
	return { cityId: id, cards };
};

const Cards = connect(
	mapStateToProps,
	null
)(CardsComponent);

export default Cards;
