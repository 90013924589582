import "./advantage-of-using.scss";
import React, { useEffect, useState } from "react";
import cn from "classnames";

const AdvantageOfUsing = ({ className, list }) => {
	const [list2Column, setList2Column] = useState(undefined);

	useEffect(() => {
		if (list) {
			if (list.length > 2) {
				const newList = [];
				let countInColumn = Math.ceil(list.length / 2);
				const firstColumn = list.slice(0, countInColumn);
				const secondColumn = list.slice(countInColumn, list.length);

				for (let i = 0; i < countInColumn; i++) {
					firstColumn[i] && newList.push(firstColumn[i]);
					secondColumn[i] && newList.push(secondColumn[i]);
				}
				setList2Column(newList);
			} else {
				setList2Column(list);
			}
		}
	}, [list]);

	return (
		<div className={cn("advantage-of-using", className)}>
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-sm-12 col-md-12">
						<h3 className="advantage-of-using__header">
							Преимущества использования
						</h3>
					</div>
				</div>
				<div className="row list hidden-xs">
					{list2Column &&
						list2Column.length &&
						list2Column.map((item, index) => (
							<div
								key={item.id}
								className={cn("col-sm-6 col-md-4", {
									"col-md-offset-2": index % 2 === 0
								})}
							>
								<div className="advantage-of-using__section">
									<div className="advantage-of-using__section-index">
										<span>{list.indexOf(item) + 1}</span>
									</div>
									<p className="advantage-of-using__section-text">
										{item.item}
									</p>
								</div>
							</div>
						))}
					<div className="hidden-xs col-sm-2 col-md-2" />
				</div>
				<div className="row list hidden-sm hidden-md">
					{list &&
						list.length &&
						list.map((item, index) => (
							<div key={item.id} className="col-xs-12">
								<div className="advantage-of-using__section">
									<div className="advantage-of-using__section-index">
										<span>{index + 1}</span>
									</div>
									<p className="advantage-of-using__section-text">
										{item.item}
									</p>
								</div>
							</div>
						))}
					<div className="hidden-xs col-sm-2 col-md-2" />
				</div>
			</div>
		</div>
	);
};

export default AdvantageOfUsing;
