import React from "react";
import Cards from "@page-contents/cards";

export default props => {
	const { location: { search } = {} } = props;
	const code =
		search && search.split("?type=") ? search.split("?type=")[1] : undefined;

	return <Cards pageContext={{ code: code }} />;
};
