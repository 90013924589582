import "./how-to-get.scss";
import React from "react";
import cn from "classnames";

const HowToGet = ({ className, list }) => {
	return (
		<div className={cn("how-to-get", className)}>
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-sm-12 col-md-12">
						<h3 className="how-to-get__header">Как получить</h3>
					</div>
				</div>
				<div className="row list">
					{list &&
						list.length &&
						list.map((item, index) => (
							<div
								key={item.id}
								className="col-xs-12 col-sm-12 col-md-offset-2 col-md-8"
							>
								<div className="how-to-get__section">
									<div className="how-to-get__section-index">
										<span>{index + 1}</span>
									</div>
									<p className="how-to-get__section-text">{item.item}</p>
								</div>
							</div>
						))}
					<div className="hidden-xs col-sm-2 col-md-2" />
				</div>
			</div>
		</div>
	);
};

export default HowToGet;
